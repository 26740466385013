import {
  ButtonLink,
  Flex,
  Pagination,
  Table,
  TableColumn,
  TableColumnSort,
  Tag,
  Text,
} from '@applyboard/crystal-ui'
import { useEffect, useMemo, useState } from 'react'
import { AgentData, ProfileData } from '../../clients'
import { LoadingPage } from '../../pages/LoadingPage'
import { EyeOutlineIcon } from '@applyboard/ui-icons'
import { useGetAllAgents } from 'src/hooks/agents/useGetAllAgents'

const FilterableKeys = ['givenName', 'familyName', 'email', 'agencyName'] as const
type FilterableKey = (typeof FilterableKeys)[number]

const PAGE_SIZE = 10

type AgentManagementTableProps = {
  currentSessionUser: ProfileData | undefined
  isGetProfileLoading: boolean
  filter: string
  search: string
}
export function AgentManagementTable({
  currentSessionUser,
  isGetProfileLoading,
  filter,
  search,
}: AgentManagementTableProps): JSX.Element {
  const [page, setPage] = useState(0)

  const { allAgentsData, isAgentsLoading } = useGetAllAgents()

  const currentSessionUserIsResolved = !isGetProfileLoading && currentSessionUser

  const [data, setData] = useState<Array<AgentData> | undefined>(allAgentsData)

  useEffect(() => {
    if (allAgentsData) {
      const filteredData = getFilteredData(allAgentsData, filter, search)
      setData(filteredData)
    }
  }, [allAgentsData, filter, search])

  const columns = useMemo<TableColumn<AgentData>[]>(
    () => [
      {
        accessorKey: 'givenName',
        header: 'Given Name',
        enableSorting: true,
        cell: (agent: AgentData) => {
          return <Table.Cell>
            <Text>{agent.profile.givenName}</Text>
          </Table.Cell>
        },
      },
      {
        accessorKey: 'familyName',
        header: 'Family Name',
        enableSorting: true,
        cell: (agent: AgentData) => {
          return <Table.Cell>
            <Text>{agent.profile.familyName}</Text>
          </Table.Cell>
        },
      },
      {
        accessorKey: 'email',
        header: 'Email',
        enableSorting: true,
        cell: (agent: AgentData) => {
          return <Table.Cell>
            <Text>{agent.profile.email}</Text>
          </Table.Cell>
        },
      },
      {
        accessorKey: 'agencyName',
        header: 'Agency',
        enableSorting: true,
        cell: (agent: AgentData) => {
          return <Table.Cell>
            <Text>{agent.agency.agencyName}</Text>
          </Table.Cell>
        },
      },
      {
        accessorKey: 'apps',
        header: 'App Access',
        cell: () => {
          return (
            <Table.Cell>
              <Flex gap={1}>
                <Tag key={crypto.randomUUID()} intent={'secondary'}>
                  AMS
                </Tag>
              </Flex>
            </Table.Cell>
          );
        },
      },
      {
        accessorKey: 'actions',
        header: 'Actions',
        cell: (agent: AgentData) =>
          currentSessionUserIsResolved ? (
            <Table.Cell>
              <ButtonLink
                variant="transparent"
                href={`/profiles/agent-users/${agent.id}`}
                aria-label="Edit User"
                leadIcon={EyeOutlineIcon}
              />
            </Table.Cell>
          ) : (
            <LoadingPage />
          ),
      },
    ],
    [
      currentSessionUser?.id,
      currentSessionUser?.userType,
      currentSessionUserIsResolved,
    ]
  )

  return (
    <>
      <Flex pt={{ xs: 2, sm: 8 }} direction="column" justify="center">
        <Table
          loading={isAgentsLoading}
          columns={columns}
          data={
            data?.slice(page * PAGE_SIZE, page * PAGE_SIZE + PAGE_SIZE) ?? []
          }
          onSort={(newSorting) => {
            if (newSorting.length === 0 && allAgentsData) {
              setData(getFilteredData(allAgentsData, filter, search))
            } else {
              if (data) {
                const sortedData = data.slice()
                sortedData.sort((a, b) => sortAgentData(newSorting, a, b))
                setData(sortedData)
              }
            }
          }}
        >
          <></>
        </Table>
      </Flex>
      <Flex pt={{ xs: 2, sm: 8 }} justify="start" align="center" basis="100%">
        {!isAgentsLoading && (
          <Pagination
            currentPage={page + 1}
            numItems={Math.min(allAgentsData?.length ?? 0, data?.length ?? 0)}
            onPageChange={(page) => setPage(page - 1)}
            pageSize={PAGE_SIZE}
          />
        )}
      </Flex>
    </>
  )
}

export function sortAgentData(sorting: TableColumnSort, a: AgentData, b: AgentData) {
  if (!a || !b) {
    return 0;
  }

  if (sorting[0].id === 'id') {
    return (sorting[0].desc ? -1 : 1) * a.id.localeCompare(b.id);
  }

  const sortKey1 = sorting[0].id as FilterableKey;
  const valueA1 = getNestedAgentValue(a, sortKey1);
  const valueB1 = getNestedAgentValue(b, sortKey1);

  const sortResult1 = (sorting[0].desc ? -1 : 1) * valueA1.localeCompare(valueB1);
  if (sortResult1 !== 0 || sorting.length === 1) {
    return sortResult1;
  }

  const sortKey2 = sorting[1].id as FilterableKey;
  const valueA2 = getNestedAgentValue(a, sortKey2);
  const valueB2 = getNestedAgentValue(b, sortKey2);

  return (sorting[1].desc ? -1 : 1) * valueA2.localeCompare(valueB2);
}

function getFilteredData(
  allAgentData: Array<AgentData>,
  filter: string,
  search: string
): Array<AgentData> {
  if (filter !== '' && search !== '') {
    const agentFilter = filter as FilterableKey;

    const filteredAgents = allAgentData.filter((agent) => {
      const value = getNestedAgentValue(agent, agentFilter);
      return value.toLowerCase().includes(search.toLowerCase());
    });

    return filteredAgents;
  }

  return allAgentData;
}

export function getNestedAgentValue(agent: AgentData, key: FilterableKey): string {
  switch (key) {
    case 'givenName':
      return agent.profile.givenName;
    case 'familyName':
      return agent.profile.familyName || '';
    case 'email':
      return agent.profile.email;
    case 'agencyName':
      return agent.agency.agencyName || '';
    default:
      throw new Error(`Unknown key: ${key}`);
  }
}
