import { ButtonLink, Flex, Select, TextInput } from '@applyboard/crystal-ui'
import { PersonAddOutlineIcon } from '@applyboard/ui-icons'
import { useState } from 'react'

interface OptionProps {
  label: string
  value: string
  disabled?: boolean
}

const FilterableKeys = ['givenName', 'familyName', 'email', 'agencyName'] as const
type FilterableKey = (typeof FilterableKeys)[number]

type AgentManagementHeadingProps = {
  canCreateAgent: boolean
  onFilterChange: (searchText: string, filter: string) => void
}

export function AgentManagementHeading({
  canCreateAgent,
  onFilterChange,
}: AgentManagementHeadingProps): JSX.Element {
  const [filter, setFilter] = useState<FilterableKey>('givenName')
  const [search, setSearch] = useState('')

  const FILTER_OPTIONS: Array<OptionProps> = [
    {
      label: 'Given Name',
      value: 'givenName',
    },
    {
      label: 'Family Name',
      value: 'familyName',
    },
    {
      label: 'Email',
      value: 'email',
    },
    {
      label: 'Agency',
      value: 'agencyName',
    },
    {
      label: 'App Access',
      value: 'apps',
      disabled: true,
    },
  ]

  const handleChangeFilter = (newFilter: string) => {
    if (isValidFilterKey(newFilter)) {
      setFilter(newFilter)
      onFilterChange(search, newFilter)
    }
  }

  const handleSearchChange = (newSearch: string) => {
    setSearch(newSearch)
    onFilterChange(newSearch, filter)
  }

  const isValidFilterKey = (filterKey: string): filterKey is FilterableKey => {
    return FilterableKeys.includes(filterKey as FilterableKey)
  }

  return (
    <Flex pt={{ xs: 2, sm: 8 }} justify="end" align="center" gap={5}>
      <TextInput
        disabled={!isValidFilterKey(filter)}
        helpText=""
        aria-label="search"
        intent="secondary"
        onChange={handleSearchChange}
        placeholder="Search"
        size="md"
        type="search"
        value={search}
      />
      {/* This should be a list of agencies */}
      <Flex gap={2} align="center">
        <Select
          aria-label="filter options"
          onChange={handleChangeFilter}
          value={filter}
          placeholder=""
          options={FILTER_OPTIONS}
        />
      </Flex>
      {canCreateAgent && (
        <ButtonLink
          href="/profiles/agent-users/add"
          leadIcon={PersonAddOutlineIcon}
          intent="primary"
          size="md"
          width="hug"
        >
          Add agent user
        </ButtonLink>
      )}
    </Flex>
  )
}
