import {
  ButtonLink,
  Flex,
  Pagination,
  Table,
  TableColumn,
  TableColumnSort,
  Tag,
} from '@applyboard/crystal-ui'
import { useEffect, useMemo, useState } from 'react'
import { ProfileData, UserType, getDisplayLabel } from '../../clients'
import { useAllProfiles } from '../../hooks'
import { LoadingPage } from '../../pages/LoadingPage'
import { EyeOutlineIcon } from '@applyboard/ui-icons'

const FilterableKeys = ['givenName', 'familyName', 'email', 'userType'] as const
type FilterableKey = (typeof FilterableKeys)[number]

const PAGE_SIZE = 10

type UserManagementTableProps = {
  currentSessionUser: ProfileData | undefined
  isGetProfileLoading: boolean
  filter: string
  search: string
}
export function UserManagementTable({
  currentSessionUser,
  isGetProfileLoading,
  filter,
  search,
}: UserManagementTableProps): JSX.Element {
  const [page, setPage] = useState(0)

  const { allProfileData, isProfilesLoading } = useAllProfiles()

  const currentSessionUserIsResolved =
    !isGetProfileLoading && currentSessionUser

  const [data, setData] = useState<Array<ProfileData> | undefined>(
    allProfileData
  )

  useEffect(() => {
    if (allProfileData) {
      const filteredData = getFilteredData(allProfileData, filter, search)
      setData(filteredData)
    }
  }, [allProfileData, filter, search])

  const columns = useMemo<TableColumn<ProfileData>[]>(
    () => [
      {
        accessorKey: 'givenName',
        header: 'Given Name',
        enableSorting: true,
      },
      {
        accessorKey: 'familyName',
        header: 'Family Name',
        enableSorting: true,
      },
      {
        accessorKey: 'email',
        header: 'Email',
        enableSorting: true,
      },
      {
        accessorKey: 'apps',
        header: 'App Access',
        cell: ({ apps }: ProfileData) => {
          const dataApps = apps.map((app) => ({
            key: crypto.randomUUID(),
            app,
          }))

          return (
            <Table.Cell>
              <Flex gap={1}>
                {dataApps?.map(({ key, app }) => (
                  <Tag key={key} intent={'secondary'}>
                    {app}
                  </Tag>
                ))}
              </Flex>
            </Table.Cell>
          )
        },
      },
      {
        accessorKey: 'userType',
        header: 'User Type',
        enableSorting: true,
        cell: ({ userType }: ProfileData) => (
          <Table.Cell>
            <Tag
              intent={
                (
                  {
                    [UserType.owner]: 'negative',
                    [UserType.admin]: 'warning',
                    [UserType.staff]: 'positive',
                  } as const
                )[userType]
              }
            >
              {getDisplayLabel(userType)}
            </Tag>
          </Table.Cell>
        ),
      },
      {
        accessorKey: 'actions',
        header: 'Actions',
        cell: (profile: ProfileData) =>
          currentSessionUserIsResolved ? (
            <Table.Cell>
              <ButtonLink
                variant="transparent"
                href={`/profiles/school-users/${profile.id}`}
                aria-label="Edit User"
                leadIcon={EyeOutlineIcon}
              />
            </Table.Cell>
          ) : (
            <LoadingPage />
          ),
      },
    ],
    [
      currentSessionUser?.id,
      currentSessionUser?.userType,
      currentSessionUserIsResolved,
    ]
  )

  return (
    <>
      <Flex pt={{ xs: 2, sm: 8 }} direction="column" justify="center">
        <Table
          loading={isProfilesLoading}
          columns={columns}
          data={
            data?.slice(page * PAGE_SIZE, page * PAGE_SIZE + PAGE_SIZE) ?? []
          }
          onSort={(newSorting) => {
            if (newSorting.length === 0 && allProfileData) {
              setData(getFilteredData(allProfileData, filter, search))
            } else {
              if (data) {
                const sortedData = data.slice()
                sortedData.sort((a, b) => sortData(newSorting, a, b))
                setData(sortedData)
              }
            }
          }}
        >
          <></>
        </Table>
      </Flex>
      <Flex pt={{ xs: 2, sm: 8 }} justify="start" align="center" basis="100%">
        {!isProfilesLoading && (
          <Pagination
            currentPage={page + 1}
            numItems={Math.min(allProfileData?.length ?? 0, data?.length ?? 0)}
            onPageChange={(page) => setPage(page - 1)}
            pageSize={PAGE_SIZE}
          />
        )}
      </Flex>
    </>
  )
}

/** From Crystal UI storybook */
function sortData(sorting: TableColumnSort, a: ProfileData, b: ProfileData) {
  if (!a || !b) {
    return 0
  }
  if (sorting[0].id === 'id') {
    return (sorting[0].desc ? -1 : 1) * a.id.localeCompare(b.id)
  }

  const sortKey1 = sorting[0].id as FilterableKey
  const sortResult1 =
    (sorting[0].desc ? -1 : 1) * a[sortKey1].localeCompare(b[sortKey1])
  if (sortResult1 !== 0 || sorting.length === 1) {
    return sortResult1
  }
  const sortKey2 = sorting[1].id as FilterableKey
  return (sorting[1].desc ? -1 : 1) * a[sortKey2].localeCompare(b[sortKey2])
}

function getFilteredData(
  allProfileData: Array<ProfileData>,
  filter: string,
  search: string
): Array<ProfileData> {
  if (filter !== '' && search !== '') {
    const profileFilter = filter as FilterableKey

    const filteredProfiles = allProfileData.filter((profile) =>
      profile[profileFilter].toLowerCase().includes(search.toLowerCase())
    )

    return filteredProfiles
  }

  return allProfileData
}
